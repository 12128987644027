.slider {
    justify-content: center;
   
  }
  
  .slider-inner {
    display: flex;
    transition: transform 2s ease-in-out; /* Smooth transition over 1 second */
  }
  
  .slideh {
    min-width: 100px;
    margin-right: 50px; /* Add gap between logos */
    /* display: flex; */
    justify-content: center;
    align-items: center;
  }
  
  /* img {
    width: 100px; 
    height: auto;
  } */
  .slider {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding-bottom: 3vw;
  }
  
  .slider-inner {
    display: flex;
    transition:  transform 2s ease-in-out;
  }
  
  .slideh {
    min-width: 100px;
    margin-right: 50px; /* Add gap between logos */
    /* display: flex; */
    justify-content: center;
    align-items: center;
  }

  
  /* .slideh img {
    width: 100px;
    height: auto;
  } */

    /* Add New Styles for offer page */

    .OfferPageSlider{
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      padding-bottom: 3vw;
      padding-top: 8vw;
    }
    .OfferPageSliderblackfriday{
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      padding-bottom: 3vw;
      padding-top: 8vw;
    }
    .slideOffer {
      min-width: 100px;
      justify-content: center;
      align-items: center;
    }
  
  @media (max-width: 768px) {
    .slider-inner {
      flex-direction: row;
      justify-content: center;
      gap: 4vw;
    }
  
    .slideh {
      margin: 0;
      width: 100%;
    }
  
    .slideh img {
      width: 100px; /* Adjust to desired size */
      height: auto;
    }
  }
  
  